// for Language btn
export const languages = [
  {
    code: "en",
    name: "English",
    country_code: "gb",
  },
  {
    code: "ar",
    name: "العربية",
    dir: "rtl",
    country_code: "sa",
  },
];
export const languages2 = {
  en: {
    code: "en",
    name: "English",
    country_code: "gb",
  },
  ar: {
    code: "ar",
    name: "العربية",
    dir: "rtl",
    country_code: "sa",
  },
};
